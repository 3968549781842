<template>
  <div class="payment-content d-flex">
      <div>
          <payment-method />
      </div>
      <div>
          <payment-input />
      </div>
  </div>
</template>

<script>
import PaymentInput from '@/components/payment/partials/PaymentInput.vue'
import PaymentMethod from '@/components/payment/partials/PaymentMethod.vue'
export default {
  components: { PaymentMethod, PaymentInput },

}
</script>

<style lang="scss" scoped>
    .payment-content{
        width: 762px;
        margin: auto;
        min-height: 500px;
        gap: 100px;
    }
</style>