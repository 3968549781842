<template>
    <div class="mt-3  border-top pt-4">
            <span class="color-grey-100 font-18 font-700 font-nunito">Bank: </span>
        <div class="d-flex flex-wrap gap-3 mt-3">
            <button class="button button--sm button--outline-primary  font-16"  
            v-for="method in selectedPaymentMethodType.payment_methods" 
            @click="SET_SELECTED_PAYMENT_METHOD(method)" 
            :class="{'active' : selectedPaymentMethod.id === method.id}" 
            :key="method.id">
                <img class="payment-logo" :src="method.logo" :alt="method.name">
            </button>
         
        </div>
            
        </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
    computed: {
        ...mapState('payment', ['selectedPaymentMethodType', 'selectedPaymentMethod'])
    },
    methods : {
        ...mapMutations('payment', ['SET_SELECTED_PAYMENT_METHOD'])
    }
}
</script>

<style lang="scss" scoped>
    .payment-logo {
        width: 76px;
        height: 24px;
        object-fit: cover;
    }
</style>