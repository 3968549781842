<template>
  <div class=" payment-header py-4">
    <div class="d-flex justify-content-between align-items-center px-5">
        <button class="button button--outline-grey font-700 d-flex align-items-center gap-3" @click="$router.push('/')">
            <img src="@/assets/icons/back-icon.svg" alt="">
            Kembali
        </button>   

        <span class="color-blue-dark-50 font-700 font-28 font-nunito">{{usePoint ?  formatRupiah(finalPrice - calculatedPoint, 'Rp.') : formatRupiah(finalPrice, 'Rp.')}}</span>
        
        <button class="button button--primary font-700 d-flex align-items-center gap-3" @click="sendOrder" :disabled="disableSubmit">
            Lanjutkan
            <img src="@/assets/icons/next-icon.svg" alt="">
        </button>
    </div>
  </div>
</template>

<script>
import IhsMixin from '@/mixins/ihs.mixins.js'

import {mapState ,mapActions ,mapGetters, mapMutations} from 'vuex'

export default {
    mixins: [IhsMixin],
    computed : {
        ...mapState('payment', ['selectedPaymentMethod', 'selectedPaymentMethodType', 'inputData', 'paymentProof', 'usePoint']),
        ...mapState('cart', [ 'extraValue', 'cartUseGroceryPrice']),
        ...mapState('user', ['calculatedPoint']),
        ...mapGetters('cart', ['finalPrice']),
        validateInput() {
            if( Object.keys(this.selectedPaymentMethodType).length ) {
                if (this.selectedPaymentMethodType.name.toLowerCase() === 'edc'  ) {
                    if (!parseInt(this.inputData.replace(/\D/g, ""))) {
                        return true
                    } else {
                        return false
                    }
                      
                } else if (this.selectedPaymentMethodType.name.toLowerCase() === 'tunai') {
                    if(this.usePoint) {
                         if(parseInt(this.inputData.replace(/\D/g, "")) >= (this.finalPrice - this.calculatedPoint)) {
                            return false
                        } else {
                            return true
                        }
                    } else {
                        if(parseInt(this.inputData.replace(/\D/g, "")) >= this.finalPrice) {
                            return false
                        } else {
                            return true
                        }
                    }
                   
                }

              
            }

            return false
        },
        validateImageInput () { 
            if (this.selectedPaymentMethodType.payment_proof === 'required') {
                     if (this.paymentProof.payment_proof_image) {
                        return false
                     } else {
                         return true
                     }

            } else {
                return false
            }
        },
        disableSubmit () {
            if (this.validateInput === false && this.validateImageInput === false) {
                return false
            } else {
                return true
            }
        }
    },
    methods : {
        ...mapActions('order', ['createOrder']),
        ...mapActions('payment', ['setPaymentUsePoint']),
        ...mapMutations('user', ['RESET_SELECTED_CUSTOMER']),
        ...mapActions("shift", ["getShift"]),
        ...mapMutations('cart', ['DELETE_CART_PAYMENT', 'SET_CART_EXTRA_VALUE', 'SET_CART_USE_GROCERY_PRICE']),
        ...mapMutations('payment', ['SET_USE_POINT']),
        async sendOrder () {
            const loading = this.$loading({
                    lock: true,
                    text: 'Memproses pembayaran',
                    spinner: '',
                    background: 'rgba(255, 255, 255, 0.7)'
                });
            try {
               
                const cart = JSON.parse(localStorage.getItem('cart'))
                const payload = { 
                    data : {
                        shift_id: localStorage.getItem('active-shift-id'),
                        cart_id: cart.id,
                        payment_method_id: this.selectedPaymentMethod.id,
                        payment_method_type: this.selectedPaymentMethodType,
                        total_additional_cost: this.extraValue,
                        note: "-",
                        is_grocery: this.cartUseGroceryPrice ,
                        type: "belanja_di_toko",
                    }

                }
                if(this.selectedPaymentMethodType.payment_proof === 'required' || this.selectedPaymentMethodType.payment_proof === 'optional') {
                    payload.data = {
                        ...payload.data,
                        ...this.paymentProof
                    }
                }

                if(this.selectedPaymentMethodType.name.toLowerCase() === 'edc') {
                    payload.data['account_number'] = this.inputData.replace(/\D/g, "")
                }

                if(this.selectedPaymentMethodType.name.toLowerCase() === 'bank transfer') {
                    payload.data.payment_method_type.payment_methods = []
                }

                if(this.usePoint) {
                    payload.data['use_point'] = true
                }


                const response = await this.createOrder(payload)
                
                if(response.data.status_code === 201) {
                    loading.close();
                    this.DELETE_CART_PAYMENT()
                    this.RESET_SELECTED_CUSTOMER()
                    this.SET_USE_POINT(false)
                    this.setPaymentUsePoint(0)
                    this.SET_CART_EXTRA_VALUE(0)
                    this.SET_CART_USE_GROCERY_PRICE(false)
                    this.getShift()
                    this.$router.push('/order-success')
                }
            } catch (error) {
                loading.close();
                this.$message({
                    showClose: false,
                    message: error.response.data.message,
                    type: 'error',
                });
            }
        
        }
    }
}
</script>

<style lang="scss" scoped>
    .payment-header {
        background-color: #F8FDFF;
    }
</style>